.Wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
}

.Content {
  height: 50vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.Links,
.Inquiries {
  height: 25vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Profile {
  display: flex;
}

.ProfileName {
  font-size: 80px;
  font-weight: 800;
  line-height: 100px;
  margin-left: 50px;
}

.ProfilePic img {
  width: 300px;
  border-radius: 20%;
}

.Inquiries a,
.Inquiries a:visited {
  font-size: 30px;
  color: rgba(0, 0, 0, 0.8);
  text-decoration: none;
}

.Inquiries a span {
  color: rgba(0, 0, 0, 0.5);
}

.Links {
  display: flex;
  justify-content: space-evenly;
  width: 80vw;
  max-width: 600px;
}

@media (max-width: 800px) or (max-height: 800px) {
  .Content,
  .Links,
  .Inquiries {
    display: initial;
    height: initial;
    margin: 20px 0;
  }

  .Wrap {
    height: initial;
  }

  .Profile {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
  }

  .ProfilePic img {
    width: 100px;
    height: 100px;
  }

  .ProfileName {
    line-height: 30px;
    font-size: 20px;
    margin-left: 20px;
    margin-top: 20px;
  }

  .Links {
    display: flex;
    flex-direction: column;
  }
  .Links a {
    margin: 10px 0;
  }

  .Inquiries {
    padding-bottom: 40px;
  }

  .Inquiries a {
    font-size: 16px;
  }
}
